/*eslint-disable*/
import React from "react";

// react components for routing our app without refresh


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons

import ContactMailIcon from "@material-ui/icons/ContactMail";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import EmojiPeopleOutlinedIcon from "@material-ui/icons/EmojiPeopleOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
// core components

import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const history = useHistory();
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          href="https://alfaroabogados.blogspot.com/?m=1"
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <InfoOutlinedIcon className={classes.icons} /> Información de Interés
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          onClick={() => history.push("/quienes-somos")}
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <EmojiPeopleOutlinedIcon className={classes.icons} /> Quienes Somos
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button
         onClick={() => history.push("/sedes")}
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <HomeOutlinedIcon className={classes.icons} /> Sedes
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="http://proceso.alfaroasociados.com.co/contactenos"
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <ContactMailIcon className={classes.icons} /> Contactenos
        </Button>
      </ListItem>

     
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Siguenos en linkedin"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.linkedin.com/in/alfaro-asociados-s-a-s-87b5698b/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-linkedin"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Siguenos en instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/alfaroasociados/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Escribenos por Whatsapp"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://api.whatsapp.com/send?phone=573017560348&text=Hola!%20Quiero%20hacer%20una%20consulta"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons  + " fab fa-whatsapp "} />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}

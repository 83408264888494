import React, { useState } from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";

import ReactCardFlip from "react-card-flip";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Seguridad from "../../../assets/img/seguridad.png";
import Comercial from "../../../assets/img/comercial.png";
import Administrativo from "../../../assets/img/administrativo.png";
import Maritimo from "../../../assets/img/maritimo.png";
import Familia from "../../../assets/img/familia.png";
import Laboral from "../../../assets/img/laboral.png";
import Autor from "../../../assets/img/autor.png";
import { Button } from "@material-ui/core";
import logo from "../../../assets/img/logo.png";
import { green, purple } from "@material-ui/core/colors";
import Zoom from 'react-reveal/Zoom';
import { useHistory } from "react-router-dom";
import { ImportantDevices } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    
  },
  
  subtitulo2: {
    textAlign: "center",
    paddingTop: "0.5rem",
    paddingLeft: "0.2rem",
    paddingRight: "0.2rem",
    fontSize:'12px',
    color: "#ffffff",
    textTransform:'capitalize'
  },
 
  titulo2: {
    textAlign: "center",
    paddingTop: "2rem",
    color: "#ffffff",
    fontWeight: "bold",
  },
  grid: {
    width: "auto",
    height: "auto",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
  },
  grid2: {
    width: "auto",
    height: "auto",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
  },
  movil:{
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
      maxWidth:'250px'
    },

  }
}));

const ColorButton = withStyles((theme) => ({
  root: {
    paddingTop:'0.5rem !important',
    size:'15px',
    backgroundColor: "#232a5f",
    "&:hover": {
      backgroundColor: "#0f1c7b",
    },
  },
}))(Button);

const CardsRotate = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isFlipped, setIsFlipped] = useState(false);
  const [isFlipped1, setIsFlipped1] = useState(false);
  const [isFlipped2, setIsFlipped2] = useState(false);
  const [isFlipped3, setIsFlipped3] = useState(false);
  const [isFlipped4, setIsFlipped4] = useState(false);
  const [isFlipped5, setIsFlipped5] = useState(false);
    const [isFlipped6, setIsFlipped6] = useState(false);


  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };
  const handleClick1 = () => {
    setIsFlipped1(!isFlipped1);
  };
  const handleClick2 = () => {
    setIsFlipped2(!isFlipped2);
  };
  const handleClick3 = () => {
    setIsFlipped3(!isFlipped3);
  };
  const handleClick4 = () => {
    setIsFlipped4(!isFlipped4);
  };
  const handleClick5 = () => {
    setIsFlipped5(!isFlipped5);
  };
   const handleClick6 = () => {
    setIsFlipped6(!isFlipped6);
  };

  return (
   
    <>
      <GridContainer className={classes.card}>
        <div>
          <GridItem className={classes.grid} xs={12} sm={12} md={4}>
            <Zoom>
            <Button
              onClick={handleClick}
              style={{ width: "320px", height: "220px" }}
              className={classes.movil}
            >
              <ReactCardFlip
                isFlipped={isFlipped}
                flipDirection="horizontal"
                style={{
                  backgroundColor: "#b3342b",
                  borderRadius: "0.5em",
                  boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                }}
              >
                <Card>
                  {" "}
                  <img
                    src={Seguridad}
                    style={{
                      width: "320px",
                      height: "220px",
                      objectFit: "cover",
                      borderRadius: "0.5em",
                      boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                    }}
                  />
                </Card>

                <Card
                  style={{
                    width: "320px",
                    height: "220px",
                    backgroundColor: "#8a130aeb",
                    borderRadius: "0.5em",
                    boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                  }}
                >
                  <div className={classes.card}>
                    <img
                      style={{
                        paddingTop: "0.5rem",
                        width: "200px",
                        height: "auto",
                        borderRadius: "0.5em",
                      }}
                      src={logo}
                    />
                  </div>

                  <p className={classes.subtitulo2}>
                    Nosotros te asesoramos y acompañamos en la adopción de las
                    medidas legales para la protección de tu empresa
                  </p>
                  <div className={classes.card}>
                    <ColorButton
                      size="medium"
                      variant="contained"
                      color="primary"
                      onClick={()=>history.push('seguridad-social')}
                      style={{
                        maxWidth: "150px",
                      }}
                    >
                      Ver mas
                    </ColorButton>
                  </div>
                </Card>
              </ReactCardFlip>
            </Button>
            </Zoom>
          </GridItem>
        </div>
        <div>
          <GridItem className={classes.grid} xs={12} sm={12} md={4}>
            <Zoom>
            <Button
              onClick={handleClick1}
              style={{ width: "320px", height: "220px" }}
            >
              <ReactCardFlip
                isFlipped={isFlipped1}
                flipDirection="horizontal"
                style={{
                  backgroundColor: "#b3342b",
                  paddingTop: "0rem",
                  paddingLeft: "0rem",
                  paddingRight: "0rem",
                  paddingBottom: "0rem",
                  borderRadius: "0.5em",
                  boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                }}
              >
                <Card>
                  {" "}
                  <img
                    src={Comercial}
                    style={{
                      width: "320px",
                      height: "220px",
                      objectFit: "cover",
                      borderRadius: "0.5em",
                      boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                    }}
                  />
                </Card>

                <Card
                  style={{
                    width: "320px",
                    height: "220px",
                    backgroundColor: "#8a130aeb",
                    borderRadius: "0.5em",
                    boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                  }}
                >
                  <div className={classes.card}>
                    <img
                      style={{
                        paddingTop: "0.5rem",
                        width: "200px",
                        height: "auto",
                        borderRadius: "0.5em",
                      }}
                      src={logo}
                    />
                  </div>

                  <p className={classes.subtitulo2}>
                   -	Asesorías jurídicas en materia de sociedades y derecho corporativo.
-	Títulos valores.
-	Contratos comerciales.
-	Asesoría contable y financiera.

                  </p>
                  <div className={classes.card}>
                    <ColorButton
                      size="medium"
                      variant="contained"
                      color="primary"
                      style={{
                        maxWidth: "150px",
                      }}
                      onClick={()=>history.push('/consumidor')}
                    >
                      Ver mas
                    </ColorButton>
                  </div>
                </Card>
              </ReactCardFlip>
            </Button>
            </Zoom>
          </GridItem>
        </div>
        <div>
          <GridItem className={classes.grid} xs={12} sm={12} md={4}>
            <Zoom>
            <Button
              onClick={handleClick2}
              style={{ width: "320px", height: "220px" }}
            >
              <ReactCardFlip
                isFlipped={isFlipped2}
                flipDirection="horizontal"
                style={{
                  backgroundColor: "#b3342b",
                  paddingTop: "0rem",
                  paddingLeft: "0rem",
                  paddingRight: "0rem",
                  paddingBottom: "0rem",
                  borderRadius: "0.5em",
                  boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                }}
              >
                <Card>
                  {" "}
                  <img
                    src={Administrativo}
                    style={{
                      width: "320px",
                      height: "220px",
                      objectFit: "cover",
                      borderRadius: "0.5em",
                      boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                    }}
                  />
                </Card>

                <Card
                  style={{
                    width: "320px",
                    height: "220px",
                    backgroundColor: "#8a130aeb",
                    borderRadius: "0.5em",
                    boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                  }}
                >
                  <div className={classes.card}>
                    <img
                      style={{
                        paddingTop: "0.5rem",
                        height: "auto",
                        borderRadius: "0.5em",
                      }}
                      src={logo}
                    />
                  </div>
                  <p className={classes.subtitulo2}>
                   Nosotros te prestamos la asesoría y el
                    acompañamiento, para que interpongas o respondas las
                    demandas o reclamaciones pertinentes
                  </p>
                  <div className={classes.card}>
                    <ColorButton
                      size="medium"
                      variant="contained"
                      color="primary"
                      style={{
                        maxWidth: "150px",
                      }}
                      onClick={()=>history.push('/administrativo')}
                    >
                      Ver mas
                    </ColorButton>
                  </div>
                </Card>
              </ReactCardFlip>
            </Button>
            </Zoom>
          </GridItem>
        </div>
      </GridContainer>
      <GridContainer className={classes.card}>
        <div>
          <GridItem className={classes.grid2} xs={12} sm={12} md={4}>
            <Zoom>
            <Button
              onClick={handleClick3}
              style={{ width: "320px", height: "220px" }}
            >
              <ReactCardFlip
                isFlipped={isFlipped3}
                flipDirection="horizontal"
                style={{
                  backgroundColor: "#b3342b",
                  paddingTop: "0rem",
                  paddingLeft: "0rem",
                  paddingRight: "0rem",
                  paddingBottom: "0rem",
                  borderRadius: "0.5em",
                  boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                }}
              >
                <Card>
                  {" "}
                  <img
                    src={Maritimo}
                    style={{
                      width: "320px",
                      height: "220px",
                      borderRadius: "0.5em",
                      boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                    }}
                  />
                </Card>

                <Card
                  style={{
                    width: "320px",
                    height: "220px",
                    backgroundColor: "#8a130aeb",
                    borderRadius: "0.5em",
                    boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                  }}
                >
                  <div className={classes.card}>
                    <img
                      style={{
                        paddingTop: "0.5rem",
                        width: "200px",
                        height: "auto",
                        borderRadius: "0.5em",
                      }}
                      src={logo}
                    />
                  </div>
                  <p className={classes.subtitulo2}>
                    Contratos de transporte marítimo Reclamaciones contra
                    aseguradoras Reclamos ante aseguradoras por echazón, avería
                    y abordaje
                  </p>
                  <div className={classes.card}>
                    <ColorButton
                      size="medium"
                      variant="contained"
                      color="primary"
                      style={{
                        maxWidth: "150px",
                      }}
                      onClick={()=>history.push('/maritimo')}
                    >
                      Ver mas
                    </ColorButton>
                  </div>
                </Card>
              </ReactCardFlip>
            </Button>
            </Zoom>
          </GridItem>
        </div>
        <div>
          <GridItem className={classes.grid2} xs={12} sm={12} md={4}>
            <Zoom>
            <Button
              onClick={handleClick4}
              style={{ width: "320px", height: "220px" }}
            >
              <ReactCardFlip
                isFlipped={isFlipped4}
                flipDirection="horizontal"
                style={{
                  backgroundColor: "#b3342b",
                  paddingTop: "0rem",
                  paddingLeft: "0rem",
                  paddingRight: "0rem",
                  paddingBottom: "0rem",
                  borderRadius: "0.5em",
                  boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                }}
              >
                <Card>
                  {" "}
                  <img
                    src={Familia}
                    style={{
                      width: "320px",
                      height: "220px",
                      borderRadius: "0.5em",
                      boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                    }}
                  />
                </Card>

                <Card
                  style={{
                    width: "320px",
                    height: "220px",
                    backgroundColor: "#8a130aeb",
                    borderRadius: "0.5em",
                    boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                  }}
                >
                  <div className={classes.card}>
                    <img
                      style={{
                        paddingTop: "0.5rem",
                        width: "200px",
                        height: "auto",
                        borderRadius: "0.5em",
                      }}
                      src={logo}
                    />
                  </div>
                  <p className={classes.subtitulo2}>
                  Divorcio y Liquidación de sociedades conyugales. 
Solicitudes de declaratoria de existencia y liquidación de uniones maritales de hecho.



                  </p>
                  <div className={classes.card}>
                    <ColorButton
                      size="medium"
                      variant="contained"
                      color="primary"
                      style={{
                        maxWidth: "150px",
                      }}
                      onClick={()=>history.push('/familia')}
                    >
                      Ver mas
                    </ColorButton>
                  </div>
                </Card>
              </ReactCardFlip>
            </Button>
            </Zoom>
          </GridItem>
        </div>
        <div>
          <GridItem className={classes.grid2} xs={12} sm={12} md={4}>
            <Zoom>
            <Button
              onClick={handleClick5}
              style={{ width: "320px", height: "220px" }}
            >
              <ReactCardFlip
                isFlipped={isFlipped5}
                flipDirection="horizontal"
                style={{
                  backgroundColor: "#b3342b",
                  paddingTop: "0rem",
                  paddingLeft: "0rem",
                  paddingRight: "0rem",
                  paddingBottom: "0rem",
                  borderRadius: "0.5em",
                  boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                }}
              >
                <Card>
                  {" "}
                  <img
                    src={Laboral}
                    style={{
                      width: "320px",
                      height: "220px",
                      borderRadius: "0.5em",
                      boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                    }}
                  />
                </Card>

                <Card
                  style={{
                    width: "320px",
                    height: "220px",
                    backgroundColor: "#8a130aeb",
                    borderRadius: "0.5em",
                    boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                  }}
                >
                  <div className={classes.card}>
                    <img
                      style={{
                        paddingTop: "0.5rem",
                        width: "200px",
                        height: "auto",
                        borderRadius: "0.5em",
                      }}
                      src={logo}
                    />
                  </div>
                  <p className={classes.subtitulo2}>
                    Asesorías jurídicas en contratos de trabajo. Liquidación de prestaciones sociales. 
demandas laborales de todo tipo.

                  </p>
                  <div className={classes.card}>
                    <ColorButton
                      size="medium"
                      variant="contained"
                      color="primary"
                      style={{
                        maxWidth: "150px",
                      }}
                      onClick={()=>history.push('/laboral')}
                    >
                      Ver mas
                    </ColorButton>
                  </div>
                </Card>
              </ReactCardFlip>
            </Button>
            </Zoom>
          </GridItem>
        </div>
      </GridContainer>

      <GridContainer className={classes.card}>
        <div>
          <GridItem className={classes.grid2} xs={12} sm={12} md={4}>
            <Zoom>
            <Button
              onClick={handleClick6}
              style={{ width: "320px", height: "220px" }}
            >
              <ReactCardFlip
                isFlipped={isFlipped6}
                flipDirection="horizontal"
                style={{
                  backgroundColor: "#b3342b",
                  paddingTop: "0rem",
                  paddingLeft: "0rem",
                  paddingRight: "0rem",
                  paddingBottom: "0rem",
                  borderRadius: "0.5em",
                  boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                }}
              >
                <Card>
                  {" "}
                  <img
                    src={Autor}
                    style={{
                      width: "320px",
                      height: "220px",
                      borderRadius: "0.5em",
                      boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                    }}
                  />
                </Card>

                <Card
                  style={{
                    width: "320px",
                    height: "220px",
                    backgroundColor: "#8a130aeb",
                    borderRadius: "0.5em",
                    boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
                  }}
                >
                  <div className={classes.card}>
                    <img
                      style={{
                        paddingTop: "0.5rem",
                        width: "200px",
                        height: "auto",
                        borderRadius: "0.5em",
                      }}
                      src={logo}
                    />
                  </div>
                  <p className={classes.subtitulo2}>
                    Prestamos asesorías jurídicas en materia de propiedad intelectual y
 en materia de derecho de autor.

                  </p>
                  <div className={classes.card}>
                    <ColorButton
                      size="medium"
                      variant="contained"
                      color="primary"
                      style={{
                        maxWidth: "150px",
                      }}
                      onClick={()=>history.push('/autor')}
                    >
                      Ver mas
                    </ColorButton>
                  </div>
                </Card>
              </ReactCardFlip>
            </Button>
            </Zoom>
          </GridItem>
        </div>
      </GridContainer>
    </>
  );
};

export default CardsRotate;

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page

import logo from "../../assets/img/logo.png";
import Seguridad from "../../assets/img/abogados.jpg";
import { useHistory } from "react-router-dom";

import Contenido_Laboral from "./Contenido_Laboral";

const useStyles = makeStyles(styles);

export default function Familia(props) {
  const history = useHistory();
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        className={classes.brand}
        brand={
          <img
            src={logo}
            onClick={() => history.push("/")}
            alt="logo"
            style={{
              width: "auto",
            }}
          />
        }
        rightLinks={<HeaderLinks />}
        fixed
        color="danger"
      />
      <Parallax filter image={Seguridad}>
    
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
        <Contenido_Laboral/>
        </div>
      </div>
      <Footer />
    </div>
  );
}
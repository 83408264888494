import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import image1 from "../../../assets/img/bg6.jpg";
import image2 from "../../../assets/img/abogados.jpg";
import image3 from "../../../assets/img/abogados (2).jpg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "auto",
  //  height: "100vh",
paddingTop:'10rem'

  },
}));

const AwesomSlider = () => {
  const classes = useStyles();
  return (
    <Carousel autoPlay infiniteLoop interval={5000} showThumbs={false}>
      <div className={classes.img}>
        <img alt="Denos el placer de atenderlos" src={image1} />
      </div>
      <div className={classes.img}>
        <img alt="" src={image2} />
      </div>
      <div className={classes.img}>
        <img alt="" src={image3} />
      </div>
    </Carousel>
  );
};

export default AwesomSlider;

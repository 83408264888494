import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SocialNetwork from "./SocialNetwork";
import Zoom from 'react-reveal/Zoom';

const useStyles = makeStyles((theme) => ({
  titulo: {
    textAlign: "center",
    paddingTop: "3rem",
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  subtitulo: {
    textAlign: "center",
    paddingTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

const Servicios = () => {
  const classes = useStyles();
  return (
    <>
    <Zoom>
      <h2 className={classes.titulo}>NUESTRAS REDES SOCIALES</h2>
      </Zoom>
      <Zoom>
      <h5 className={classes.subtitulo}>
        SÍGUENOS Y ENCUENTRA INFORMACIÓN DE TU INTERÉS
      </h5>
      </Zoom>
      <Zoom>
      <SocialNetwork />
      </Zoom>
    </>
  );
};

export default Servicios;

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
// import SectionBasics from "./Sections/SectionBasics.js";
// import SectionNavbars from "./Sections/SectionNavbars.js";
// import SectionTabs from "./Sections/SectionTabs.js";
// import SectionPills from "./Sections/SectionPills.js";
// import SectionNotifications from "./Sections/SectionNotifications.js";
// import SectionTypography from "./Sections/SectionTypography.js";
// import SectionJavascript from "./Sections/SectionJavascript.js";
import SectionCarousel from "./Sections/SectionCarousel.js";
// import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
// import SectionLogin from "./Sections/SectionLogin.js";
// import SectionExamples from "./Sections/SectionExamples.js";
// import SectionDownload from "./Sections/SectionDownload.js";
import styles from "assets/jss/material-kit-react/views/components.js";
import image1 from "../../assets/img/bg6.jpg";
import image2 from "../../assets/img/bg5.jpg";
import image3 from "../../assets/img/bg4.jpg";
import logo from "../../assets/img/logo.png";
import Servicios from "./Sections/Servicios";
import Equipo from "./Sections/Equipo";
import AwesomSlider from "./Sections/AwesomeSlider";
import AddIcon from "@material-ui/icons/Add";

import Maps from "./Sections/Maps.js";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import Slide from 'react-reveal/Slide';
import Fab from './Sections/Fab'

const useStyles = makeStyles(styles);

export default function Components(props) {
  const history = useHistory();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header
        className={classes.brand}
        brand={
          <img
            src={logo}
            onClick={() => history.push("/")}
            alt="logo"
            style={{
              width: "auto",
            }}
          />
        }
        rightLinks={<HeaderLinks />}
        fixed
        color="danger"
        // changeColorOnScroll={{
        //   height: 400,
        //   color: "danger",
        // }}
        // {...rest}
      />

      <Parallax>
        <div
          style={{
            position: "absolute",
            width: "100%",
            
          }}
        >
          <AwesomSlider />
        </div>

        {/* <div className={classes.container}>
          <GridContainer>
            <GridItem></GridItem>
          </GridContainer>
        </div> */}
      </Parallax>
      
       
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{
          paddingBottom: "2rem",
        }}
      >
       <Fab/>
     
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "auto",
          }}
        >

          <Servicios />
        </div>
        
     
        <Equipo />

        <Maps />

        {/* <SectionBasics />
        <SectionNavbars />
        <SectionTabs />
        <SectionPills />
        <SectionNotifications />
        <SectionTypography />
        <SectionJavascript />

        <SectionCompletedExamples />
        <SectionLogin />
        <GridItem md={12} className={classes.textCenter}>
          <Link to={"/login-page"} className={classes.link}>
            <Button color="primary" size="lg" simple>
              View Login Page
            </Button>
          </Link>
        </GridItem>
        <SectionExamples />
        <SectionDownload /> */}
        <br></br>
      </div>
      <Footer />
   
    </div>
  );
}

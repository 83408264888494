import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PhoneAndroidOutlinedIcon from "@material-ui/icons/PhoneAndroidOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import Zoom from 'react-reveal/Zoom';


const useStyles = makeStyles((theme) => ({
  titulo: {
    textAlign: "center",
    paddingTop: "3rem",
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  subtitulo: {
    textAlign: "center",
    paddingTop: "0rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  contenedor: {
    width: "auto",
    height: "auto",
    borderRadius: "0.5em",
    boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)",
  },
  principal: {
    paddingLeft: "0rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0rem",
      paddingRight: "0rem",
      width: "100%",
      height: "120%",
    },
  },
}));

const Maps = () => {
  const classes = useStyles();
  return (
    <>
      <div>
        <Zoom>
        <h2 className={classes.titulo}>DATOS DE CONTACTO</h2>
        </Zoom>
        <Zoom>
        <h5 className={classes.subtitulo}>
          Contactese con nosotros para brindarle la asesoria que usted necesita.
        </h5>
        </Zoom>
        <Zoom>
        <h5 className={classes.subtitulo}>
          <LocationOnOutlinedIcon color="primary" /> Cartagena, Colombia Centro
          Comercial La Matuna, Oficina 209
        </h5>
        </Zoom>
        <Zoom>
        <h5 className={classes.subtitulo}>
          <PhoneAndroidOutlinedIcon color="primary" />
          +(57) 3017560348 -  3004420648
        </h5>
        </Zoom>
       
        <Zoom>
        <h5 className={classes.subtitulo}>
          <MailOutlineOutlinedIcon color="primary" /> gerencia@alfaroasociados.com.co
        </h5>
        </Zoom>
     
        <div className={classes.principal}>  
        <Zoom>
        <iframe width="80%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=comercios%20la%20matuna&t=&z=13&ie=UTF8&iwloc=&output=embed" 
        frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
        </iframe> 
        </Zoom>       
        </div>
      </div>
    

    </>
  );
};

export default Maps;

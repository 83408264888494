import React from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from "components/Grid/GridItem";
import { makeStyles } from '@material-ui/core/styles';
import Zoom from 'react-reveal/Zoom';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import Imagen from '../../assets/img/familia.png'

const useStyles = makeStyles((theme) => ({
   
    alinear:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    titulo: {
        color: "black",
        textAlign: "center",
        paddingTop: "3rem",
        paddingBottom:'1rem',
        fontWeight: "bold",
        fontFamily: "Lato, sans-serif",
        [theme.breakpoints.down("sm")]: {
          fontSize: "30px",
        },
      },

      imagen:{
        width:'400px',
        boxShadow: "2px 7px 27px 0px rgba(0,0,0,0.77)"

      },

      subtitulo:{
        fontSize:'20px',
        color: "black",
        textAlign: "justify",
        paddingTop: "1rem",
        
        fontFamily: "Lato, sans-serif",
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
        }, 
      }
    }))

const Contenido_Familia = () => {
    const classes = useStyles();
    return ( 
       <>
        <GridContainer className={classes.alinear}>
            <GridItem >
                <Zoom>          
                <h2 className={classes.titulo}>Derecho de Familia</h2>
                </Zoom>
            </GridItem>
        </GridContainer>
        <GridContainer>
            <GridItem  xs={12} sm={12} md={6} >
               <Zoom>
               <p className={classes.subtitulo}> <GavelOutlinedIcon style={{color:"#8a130aeb"}}/> Divorcio y Liquidación de sociedades conyugales.</p> 
               </Zoom>
                <Zoom>
               <p className={classes.subtitulo}> <GavelOutlinedIcon style={{color:"#8a130aeb"}}/> Solicitudes de declaratoria de existencia y liquidación de uniones maritales de hecho.</p> 
               </Zoom>
                <Zoom>
               <p className={classes.subtitulo}> <GavelOutlinedIcon style={{color:"#8a130aeb"}}/> Alimentos de mayores y menores.</p> 
               </Zoom>
               <Zoom>
               <p className={classes.subtitulo} style={{paddingBottom:'5rem'}}><GavelOutlinedIcon style={{color:"#8a130aeb"}}/> Asesorías jurídicas y litigios en materia de sucesiones.</p> 
               </Zoom>
            </GridItem>
            <GridItem className={classes.alinear} xs={12} sm={12} md={6} >
                <Zoom>
               <img src={Imagen} className={classes.imagen} style={{marginBottom:'4rem', borderRadius:'10px'}}/>
               </Zoom>
            </GridItem>
            <br></br>
        </GridContainer>
       </>
     );
}
 
export default Contenido_Familia;
import React from "react";
import Zoom from 'react-reveal/Zoom';
import { makeStyles } from "@material-ui/core/styles";
import { light } from "@material-ui/core/styles/createPalette";

const useStyles = makeStyles((theme) => ({
  titulo: {
    color: "black",
    textAlign: "center",
    paddingTop: "3rem",
    paddingBottom:'1rem',
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  subtitulo: {
    textAlign: "center",
    color: "black",
    fontWeight: 'light',
    textAlign: "justify",
   
    margin: "0 auto",
    fontSize: '18px',
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  contenedor: {
    width: "auto",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
      width: "auto",
    },
  },
}));

const Contenido = () => {

  const classes = useStyles();
  return (
   
    <div>
       <Zoom>
       <h2 className={classes.titulo}>Quienes Somos</h2>
      </Zoom>
      <Zoom>
      
      <p
       className={classes.subtitulo}
      >
        Alfaro Abogados Asociados S.A.S. Es una empresa de servicios jurídicos
        especiales y holding de negocios que con una cuidadosa selección de sus
        colaboradores, una atención de primera mano a sus clientes, suma
        diligencia en las gestiones encomendados y excelente representación de
        los mismos, ofrece los más altos estándares de calidad en el ámbito
        jurídico, judicial y corporativo, lo que nos permite ofrecer un
        innovador servicio multidisciplinar integral, en el asesoramiento y
        defensa de los intereses de nuestros clientes, velando siempre por una
        relación duradera con las personas y compañías que confían en nosotros,
        procurando un trato personalizado y directo, y la compenetración con las
        necesidades de cada cliente manteniéndonos siempre continuo proceso de
        mejora.
      </p>
      
     
      </Zoom>
      <Zoom>
      <br></br>
      <p
        className={classes.subtitulo}
      >
        Dentro de cada una de nuestras áreas de desenvolvimiento profesional,
        ofrecemos un gran servicio de asesoramiento, consulta y defensa jurídica
        en todo el territorio nacional.
      </p>
      </Zoom>
      <Zoom>
      <br></br>
      <p
        className={classes.subtitulo}
      >
        Además contamos con un selecto grupo de profesionales del derecho, cada
        uno experto en sus respectivas ramas de atención, con los estudios y
        experiencia necesarios para hacer de nuestra firma una de las mejores de
        la costa y estar entre las mejores del país, con miras a aportar a
        Colombia una firma con excelente gestión jurídica y de negocios
        corporativos basada en la ética y las buenas costumbres.
      </p>
      </Zoom>
      <Zoom>
      <br></br>
      <p
         className={classes.subtitulo}
      >
        Cada abogado, profesional, colaborador y socio, tienen no solo
        capacidades laborales, sino el ímpetu de atención y ayuda mutua, son
        personas de grandes valores morales sociales y técnicos, capaces de
        generar una reacción inmediata ante el surgimiento de cualquier problema
        jurídico produciendo resultados inmediatos y óptimos.
      </p>
      </Zoom>
      <Zoom>
      <h2 className={classes.titulo}>Historia</h2>
      <p
         className={classes.subtitulo}
      >
        Alfaro abogados asociados es una firma visionada en el año 2005, que
        cuenta con socios con 15 años de experiencia, formados en las mejores
        universidades del país con presencia en toda la costa atlántica y parte
        del interior del país.
      </p>
      </Zoom>
      <Zoom>
     
      <h2 className={classes.titulo}>Nuestros Valores</h2>
    
      <p
        className={classes.subtitulo}
      >
        Atención personalizada, agilidad, dinamismo, compromiso, inmediatez,
        rendimiento. Servicio al cliente, dedicación, Seriedad, discreción,
        profesionalismo, eficacia, trato directo, puntualidad, transparencia,
        experiencia, disponibilidad.
      </p>
      <br></br>
      <br></br>
      <br></br>
      </Zoom>
    </div>
   
  );
};

export default Contenido;

import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";
import "assets/css/prueba.css";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import Quienes_somos from "views/Quienes_somos/Quienes_somos.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import Sedes from "views/Sedes/Sedes.js";
import SeguridadSocial from "views/SeguridadSocial/SeguridadSocial.js";
import Consumidor from "views/Consumidor/Consumidor.js";
import Administrativo from "views/Administrativo/Administrativo.js";
import Maritimo from "views/Maritimo/Maritimo.js";
import Familia from "views/Familia/Familia";
import Laboral from "views/Laboral/Laboral";
import Autor from "views/Autor/Autor";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/autor" component={Autor} />
       <Route path="/laboral" component={Laboral} />
      <Route path="/familia" component={Familia} />
      <Route path="/maritimo" component={Maritimo} />
      <Route path="/administrativo" component={Administrativo} />
      <Route path="/consumidor" component={Consumidor} />
      <Route path="/seguridad-social" component={SeguridadSocial} />
      <Route path="/sedes" component={Sedes} />
      <Route path="/quienes-somos" component={Quienes_somos} />
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/" component={Components} />
    </Switch>
  </Router>,
  document.getElementById("root")
);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardsRotate from "../Sections/CardsRotate";
import Zoom from 'react-reveal/Zoom';

const useStyles = makeStyles((theme) => ({
  titulo: {
    textAlign: "center",
    paddingTop: "3rem",
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  subtitulo: {
    textAlign: "center",
    paddingTop: "1rem",
    paddingLeft:'5%',
    paddingRight:'5%',
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  contenedor: {
    width: "auto",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
      width: "auto",
    },
  },
}));

const Servicios = () => {
  const classes = useStyles();
  return (
    <>
    <Zoom>
      <h2 className={classes.titulo}>SERVICIOS JURÍDICOS DE ALTA CALIDAD</h2>
      </Zoom>
      <Zoom>
      <h5 className={classes.subtitulo}>
        ATENDEMOS LAS NECESIDADES DE NUESTROS CLIENTES Y VELAMOS POR SUS
        INTERESES
      </h5>
      </Zoom>
      <div className={classes.contenedor}>
        <CardsRotate />
      </div>
    </>
  );
};

export default Servicios;

import React from 'react';
import { Container, Button} from 'react-floating-action-button'
import './index.css'
import { useHistory } from "react-router-dom";


const redireccionar = () =>{
    window.open('http://proceso.alfaroasociados.com.co','_blank');
}


const Fab = () => {

    const history = useHistory();

    return (
       
        <div style={{
            position:'absolute',
            zIndex:'100',
           
        }}>
        <Container styles={
            {
                marginRight:'0rem'
            }
        } >
       
        <Button
            classname='fa-blink espacio'
            styles={{backgroundColor: '#8a130aeb', color: '#fff', cursor:"pointer"}}
            tooltip="Ver Estado de Procesos"
            icon="fas fa-gavel fa-2x "
            iconStyles={{ size:'20px'}}
            rotate={true}
            onClick={()=>redireccionar()}
           
            
             />
    </Container>
    </div>
      );
}
 
export default Fab;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Cartagena from "../../assets/img/cartagena.jpg"
import Barranquilla from "../../assets/img/barranquilla.jpg"
import Bogota from "../../assets/img/bogota.jpg"

import GridContainer from 'components/Grid/GridContainer';
import GridItem from "components/Grid/GridItem";

import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PhoneAndroidOutlinedIcon from "@material-ui/icons/PhoneAndroidOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import WhatsApp from "../../assets/img/whatsapp.png";
import Twitter from "../../assets/img/twitter.png";
import Facebook from "../../assets/img/facebook (1).png";
import Instagram from "../../assets/img/instagram.png"

import Zoom from 'react-reveal/Zoom';

import './styles.css'

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
      boxShadow:" 3px 2px 27px 1px rgba(12,43,242,0.75)",
    },

    grid: {
        width: 345,
        height: 'auto',
        paddingBottom:'6rem'
      },
  
    media: {
      height: 220,
      borderRadius:"0px 0px 15px 15px"
    },

    alinear:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    titulo: {
        color: "black",
        textAlign: "center",
        paddingTop: "3rem",
        paddingBottom:'1rem',
        fontWeight: "bold",
        fontFamily: "Lato, sans-serif",
        [theme.breakpoints.down("sm")]: {
          fontSize: "30px",
        },
      },
    }))

const Contenido_sedes = () => {
    const classes = useStyles();
    return ( 
        <>
            <GridContainer className={classes.alinear}>
                <GridItem >
                <Zoom>
                <h2 className={classes.titulo}>NUESTRAS SEDES</h2>
                </Zoom>
                </GridItem>
                
                <GridItem xs={12} sm={12} md={4} className={classes.grid} >                   
                    <Zoom>
                    <Card className={classes.root}>
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                image={Cartagena}
                                title="Contemplative Reptile"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2" style={{textAlign:'center'}}>
                               Cartagena
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                <p className={classes.subtitulo}>
                                <PersonOutlineOutlinedIcon color="primary"/>&nbsp; Dr. Albert Alfaro
                                </p>
                                <p className={classes.subtitulo}>
                                <LocationOnOutlinedIcon color="primary" />&nbsp; Cartagena, Colombia Centro
                                Comercial La Matuna, Oficina 209
                                </p>
                                <p className={classes.subtitulo}>
                                <PhoneAndroidOutlinedIcon color="primary" />&nbsp;
                                +(57) 3017560348
                                </p>
                                <p className={classes.subtitulo}>
                                <MailOutlineOutlinedIcon color="primary" />&nbsp; gerencia@alfaroasociados.com.co
                                </p>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        {/* <div className="centrar-imagenes">
                            <a href="https://www.google.com">
                            <img src={Twitter} width='20px' />
                            </a>
                            <a href="https://www.google.com">
                            <img src={Facebook} width='20px' />
                            </a>
                            <a href="https://www.google.com">
                            <img src={Instagram}  width='20px'/>
                            </a>
                            <a href="https://www.google.com">
                            <img src={WhatsApp} width='20px' />
                            </a>
                        </div> */}
                    </Card>
                    </Zoom>
                </GridItem>

                <GridItem xs={12} sm={12} md={4}className={classes.grid}> 
                <Zoom>                 
                    <Card className={classes.root}>
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                image={Barranquilla}
                                title="Contemplative Reptile"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2" style={{textAlign:'center'}}>
                               Barranquilla
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                <p className={classes.subtitulo}>
                                <PersonOutlineOutlinedIcon color="primary"/>&nbsp; Dr. Lelys Alemán
                                </p>
                                <p className={classes.subtitulo}>
                                <LocationOnOutlinedIcon color="primary" />&nbsp; Carrera 44#37-21 piso 13 oficina 1304-1305 edificio suramericana
                                </p>
                                <p className={classes.subtitulo}>
                                <PhoneAndroidOutlinedIcon color="primary" />&nbsp;
                                +(57)  3004161105 - 3102040615
                                </p>
                                <p className={classes.subtitulo}>
                                <MailOutlineOutlinedIcon color="primary" />&nbsp; gerencia@alfaroasociados.com.co
                                </p>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        {/* <div className="centrar-imagenes">
                            <a href="https://www.google.com">
                            <img src={Twitter} width='20px' />
                            </a>
                            <a href="https://www.google.com">
                            <img src={Facebook} width='20px' />
                            </a>
                            <a href="https://www.google.com">
                            <img src={Instagram}  width='20px'/>
                            </a>
                            <a href="https://www.google.com">
                            <img src={WhatsApp} width='20px' />
                            </a>
                        </div> */}
                    </Card>
                    </Zoom> 
                </GridItem>

                <GridItem xs={12} sm={12} md={4} className={classes.grid}>   
                <Zoom>              
                    <Card className={classes.root}>
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                image={Bogota}
                                title="Contemplative Reptile"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2" style={{textAlign:'center'}}>
                                Bogotá
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                <p className={classes.subtitulo}>
                                <PersonOutlineOutlinedIcon color="primary"/>&nbsp; Dr. Camilo Andrés Duque Ortiz
                                </p>
                                <p className={classes.subtitulo}>
                                <LocationOnOutlinedIcon color="primary" />&nbsp; Cra 13 # 61-47 ofc 220
                                </p>
                                <p className={classes.subtitulo}>
                                <PhoneAndroidOutlinedIcon color="primary" />&nbsp;
                                +(57) 3105518704
                                </p>
                                <p className={classes.subtitulo}>
                                <MailOutlineOutlinedIcon color="primary" />&nbsp; gerencia@alfaroasociados.com.co
                                </p>
                                <br/>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        {/* <div className="centrar-imagenes">
                            <a href="https://www.google.com">
                            <img src={Twitter} width='20px' />
                            </a>
                            <a href="https://www.google.com">
                            <img src={Facebook} width='20px' />
                            </a>
                            <a href="https://www.google.com">
                            <img src={Instagram}  width='20px'/>
                            </a>
                            <a href="https://www.google.com">
                            <img src={WhatsApp} width='20px' />
                            </a>
                        </div> */}
                    </Card>
                    </Zoom>  
                </GridItem>
            </GridContainer>
            </> 

    );
}
 
export default Contenido_sedes
